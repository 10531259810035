<template>
    <Dialog
        v-model:visible="isOpen"
        :style="{ width: '430px' }"
        :header="`Gerar relatório ${record?.name}`"
        @hide="closeDialog"
        :modal="true"
        :closable="!isLoading"
    >
        <div v-if="!isLoading" class="flex flex-column gap-2">
            <Message v-if="errorMessage" severity="error">{{ errorMessage }}</Message>
            <div class="flex flex-column field">
                <label for="tipoRelatorio">Modelo do relatório</label>
                <Dropdown
                    id="tipoRelatorio"
                    class="w-full"
                    v-model="v$.tipoRelatorio.$model"
                    :options="tiposRelatorio"
                    :class="{ 'p-invalid': v$.tipoRelatorio.$errors.length && v$.tipoRelatorio.lazy.$dirty }"
                    optionLabel="name"
                    dataKey="tipo"
                    placeholder="Selecione um modelo de relatório"
                    filter
                    autoFilterFocus
                    @change="validarCampoTipoRelatorio"
                />
                <small v-if="v$.tipoRelatorio.$errors.length && v$.tipoRelatorio.lazy.$dirty" class="p-error">
                    {{ v$.tipoRelatorio.$errors[0].$message }}
                </small>
            </div>
            <div class="flex flex-column field">
                <label for="dataVisita">Data da visita</label>
                <DropDownDataVersao
                    id="dataVisita"
                    v-model="v$.dataVisitaAplicacaoLevantamento.$model"
                    class="w-full"
                    :filtrosExtras="{ customerId: record.cliente?.id, branchId: record.id }"
                    showClear
                    :disabled="!form.tipoRelatorio"
                    :class="{ 'p-invalid': v$.dataVisitaAplicacaoLevantamento.$errors.length && v$.dataVisitaAplicacaoLevantamento.lazy.$dirty }"
                />
                <small v-if="v$.dataVisitaAplicacaoLevantamento.$errors.length && v$.dataVisitaAplicacaoLevantamento.lazy.$dirty" class="p-error">
                    {{ v$.dataVisitaAplicacaoLevantamento.$errors[0].$message }}
                </small>
            </div>
            <div class="field-checkbox mb-3">
                <InputSwitch id="enviarPorEmail" v-model="form.enviarPorEmail" />
                <label for="enviarPorEmail">Enviar por e-mail</label>
            </div>
            <div class="field p-fluid">
                <label for="emails">E-mail</label>
                <Chips
                    id="emails"
                    :disabled="!form?.enviarPorEmail"
                    v-model="v$.emails.$model"
                    placeholder="Digite um e-mail"
                    separator=","
                    :class="{ 'p-invalid': v$.emails.$errors.length && v$.emails.lazy.$dirty }"
                    aria-describedby="emails-help"
                />
                <small v-if="!v$.emails.$errors.length" id="emails-help">Para adicionar mais de um e-mail utilize a vírgula.</small>
                <small v-if="v$.emails.$errors.length && v$.emails.lazy.$dirty" class="p-error">
                    {{ v$.emails.$errors[0].$message }}
                </small>
            </div>
        </div>
        <div v-else class="flex align-items-center justify-content-center flex-wrap gap-4 mt-3 py-8">
            <i class="pi pi-spin pi-spinner text-primary" style="font-size: 5rem"></i>
            <span class="flex align-items-center justify-content-center text-2xl" style="min-width: 100%">{{ textoGerar }}</span>
        </div>
        <template #footer>
            <Button label="Cancelar" :disabled="isLoading" class="p-button-text justify-content-end" @click="closeDialog" />
            <Button label="Gerar" :loading="isLoading" class="p-button-primary justify-content-end" @click="gerar" />
        </template>
    </Dialog>
</template>

<script setup>
import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import axios from 'axios';
import { defineEmits, ref, defineExpose, watch } from 'vue';
import { getTenant, getToken } from '../../../common/storage';
import SesmtService from '../../../services/SesmtService';
import DropDownDataVersao from './DropDownDataVersao';

const emit = defineEmits(['onSuccessEnvioRelatorioPorEmail']);
const isOpen = ref(false);
const errorMessage = ref(null);
const isLoading = ref(false);
const record = ref(null);
const hasProdutoLppContratado = ref(false);
const form = ref({
    enviarPorEmail: false,
    emails: [],
    dataVisitaAplicacaoLevantamento: null
});
const textoGerar = ref(null);
const tiposRelatorio = ref([
    { name: 'Levantamento Preliminar de Perigo (LPP) - visita técnica', tipo: 'PDF' },
    { name: 'Quantificação complementar', tipo: 'XLS' }
]);

const rules = {
    tipoRelatorio: {
        required: helpers.withMessage('O modelo do relatório é obrigatório.', required),
        validacaoPersonalizadaTipoRelatorio: helpers.withMessage(
            'Unidade não possui o Levantamento Preliminar de Perigo contratado.',
            () => hasProdutoLppContratado.value
        ),
        lazy: true
    },
    dataVisitaAplicacaoLevantamento: {
        required: helpers.withMessage('A data da visita é obrigatória.', required),
        lazy: true
    },
    emails: {
        required: helpers.withMessage(
            'Ao menos um e-mail deve ser informado',
            requiredIf(() => form.value?.enviarPorEmail)
        ),
        format: helpers.withMessage('Pelo menos um dos e-mails informados não é válido.', (value) => {
            if (!form.value?.enviarPorEmail || !value || !value.length) {
                return true;
            }
            const emailsAlterados = value.map((p) => p.trim());
            return emailsAlterados.every((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
        }),
        lazy: true
    }
};

const v$ = useVuelidate(rules, form);

watch(
    () => form.value.emails,
    () => {
        validarCampoEmail();
    }
);

async function validarCampoTipoRelatorio() {
    await buscarVisitasTecnica();
    v$.value.tipoRelatorio.$touch();
}

function validarCampoEmail() {
    v$.value.emails.$touch();
}

async function buscarVisitasTecnica() {
    const filtrosExtras = { customerId: record.value.cliente?.id, branchId: record.value.id };
    const records = await new SesmtService('/inventario-risco-visita-tecnica').findAll({ page: 1, limit: 10, filtrosExtras });

    if (!records.data.items.length) errorMessage.value = 'Não existe nenhuma visita concluída para esta unidade';
}

function closeDialog() {
    isOpen.value = false;
    v$.value.$reset();
    form.value = {
        enviarPorEmail: false,
        emails: []
    };
    errorMessage.value = null;
    textoGerar.value = null;
}

async function openDialog(data, possuiProdutoLppContratado) {
    record.value = data;
    hasProdutoLppContratado.value = possuiProdutoLppContratado;
    isOpen.value = true;
}

async function gerar() {
    const isValid = await v$.value.$validate();

    if (!isValid) return;

    const dto = {
        customer: {
            id: record.value.cliente.id,
            name: record.value.cliente.name
        },
        customerBranch: {
            id: record.value.id,
            name: record.value.name
        },
        enviarPorEmail: form.value.enviarPorEmail,
        emails: form.value.emails,
        inventarioRiscoVisitaTecnicaId: form.value.dataVisitaAplicacaoLevantamento?.id
    };
    switch (form.value?.tipoRelatorio?.tipo) {
        case 'PDF':
            gerarRelatorio(dto);
            break;
        case 'XLS':
            exportarDados(dto);
            break;
        default:
            break;
    }
}

async function gerarRelatorio(dto) {
    try {
        isLoading.value = true;
        textoGerar.value = form.value.enviarPorEmail ? 'Enviando relatório' : 'Gerando relatório';
        const rotaGeracaoRelatorio = form.value.enviarPorEmail ? 'enviar-por-email' : 'gerar';

        const response = await axios.post(process.env.VUE_APP_API_SESMT_URL + `/inventario-risco/relatorios/${rotaGeracaoRelatorio}`, dto, {
            method: 'POST',
            responseType: form.value.enviarPorEmail ? 'json' : 'blob',
            headers: {
                Authorization: `Bearer ${getToken()}`,
                'x-api-key': getTenant()
            }
        });

        if (!form.value.enviarPorEmail) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const blobURL = URL.createObjectURL(blob);

            window.open(blobURL, '_blank');
        }

        form.value.enviarPorEmail && emit('onSuccessEnvioRelatorioPorEmail');

        closeDialog();

        isLoading.value = false;
    } catch (error) {
        errorMessage.value = getMessageError(error);
        isLoading.value = false;
        textoGerar.value = null;
    }
}

async function exportarDados(dto) {
    try {
        isLoading.value = true;
        textoGerar.value = form.value.enviarPorEmail ? 'Enviando relatório' : 'Gerando relatório';
        const rotaGeracaoRelatorio = form.value.enviarPorEmail ? 'ghe/enviar-por-email' : 'ghe';
        const res = await axios.post(process.env.VUE_APP_API_SESMT_URL + `/inventario-risco/exportar/${rotaGeracaoRelatorio}`, dto, {
            responseType: form.value.enviarPorEmail ? 'json' : 'blob',
            headers: {
                Authorization: `Bearer ${getToken()}`,
                'x-api-key': getTenant()
            }
        });

        if (!form.value.enviarPorEmail) {
            const file = window.URL.createObjectURL(new Blob([res.data]));
            const docUrl = document.createElement('a');
            docUrl.href = file;
            docUrl.setAttribute('download', `${dto.customerBranch.id}-relatório_ghe.xlsx`);
            document.body.appendChild(docUrl);
            docUrl.click();
        }

        form.value.enviarPorEmail && emit('onSuccessEnvioRelatorioPorEmail');

        closeDialog();

        isLoading.value = false;
    } catch (error) {
        errorMessage.value = getMessageError(error);
        isLoading.value = false;
        textoGerar.value = null;
    }
}

function getMessageError(error) {
    let message;
    if (!form.value.enviarPorEmail) {
        message = 'Erro ao gerar o relatório. Tente novamente.';
        return message;
    }

    message = error?.response?.data?.message || error.message || error;

    return message;
}

defineExpose({
    openDialog
});
</script>
