<script setup>
import { ref, defineExpose, reactive, watch, onMounted, defineProps } from 'vue';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import DropdownElaboradorPgr from '../DropdownElaboradorPgr.vue';

const props = defineProps({
    customerBranch: Object
});

const isLoading = ref(false);
const form = ref({});
const filtrosExtras = ref({ where: [] });
const customerBranchAtual = ref({});

const rules = reactive({
    elaboradorPgr: {
        required: helpers.withMessage('O elaborador é obrigatório.', required),
        validacaoPersonalizadaElaboradorPgr: helpers.withMessage(
            'O elaborador selecionado não possui usuário vinculado.',
            (value) => !!value?.usuarioVinculadoId
        ),
        lazy: true
    },
    emails: {
        required: helpers.withMessage(
            'Ao menos um e-mail deve ser informado',
            requiredIf(() => form.value?.enviarPorEmail)
        ),
        format: helpers.withMessage('Pelo menos um dos e-mails informados não é válido.', (value) => {
            if (!form.value?.enviarPorEmail || !value || !value.length) {
                return true;
            }
            const emailsAlterados = value.map((p) => p.trim());
            return emailsAlterados.every((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
        }),
        lazy: true
    }
});

const v$ = useVuelidate(rules, form);

watch(
    () => form.value.emails,
    async () => validarCampoEmail()
);

function updateEmails() {
    if (!form.value.enviarPorEmail) form.value.emails = [];
    const { enviarPorEmail, elaboradorPgr } = form.value;
    if (enviarPorEmail && elaboradorPgr?.usuarioVinculado?.email) {
        form.value.emails = [elaboradorPgr.usuarioVinculado.email];
    }
}

watch(() => form.value.elaboradorPgr, updateEmails);

watch(() => form.value.enviarPorEmail, updateEmails);

function validarCampoElaboradorPgr() {
    v$.value.elaboradorPgr.$touch();
    if (form.value?.elaboradorPgr?.usuarioVinculado?.email) form.value.emails = [form.value.elaboradorPgr.usuarioVinculado.email];
}

const validarCampoEmail = () => v$.value.emails.$touch();

function montarFiltroCliente(customerId) {
    if (customerId)
        filtrosExtras.value.where.push({
            prop: 'cliente.id',
            operator: 'equal',
            values: [customerId]
        });
}

function montarFiltroUnidade(customerBranchId) {
    if (customerBranchId)
        filtrosExtras.value.where.push({
            prop: 'customerBranch.id',
            operator: 'equal',
            values: [customerBranchId]
        });
}
onMounted(() => {
    customerBranchAtual.value = props.customerBranch;
    montarFiltroCliente(props.customerBranch?.cliente.id);
    montarFiltroUnidade(props.customerBranch?.id);
});

function validarCampos() {
    v$.value.$touch();
    if (!v$.value.$invalid) {
        return true;
    }
    return false;
}

function limparCampos() {
    form.value = {};
    v$.value.$reset();
    filtrosExtras.value = { where: [] };
}

defineExpose({ form, v$, validarCampos, limparCampos });
</script>
<template>
    <Panel toggleable>
        <template #header>
            <div class="flex flex-column gap-2">
                <span class="font-bold">PGR</span>
                <span class="font-normal">Programa de gerenciamento de riscos</span>
            </div>
        </template>

        <div v-if="!isLoading" class="flex flex-column gap-2">
            <div class="flex flex-column field">
                <label for="elaboradorPGR">Elaborador PGR</label>
                <DropdownElaboradorPgr
                    id="elaboradorPGR"
                    class="w-full"
                    v-model="v$.elaboradorPgr.$model"
                    :filtrosExtras="null"
                    :class="{ 'p-invalid': v$.elaboradorPgr.$errors.length && v$.elaboradorPgr.lazy.$dirty }"
                    @change="validarCampoElaboradorPgr"
                    showClear
                />
                <small v-if="v$.elaboradorPgr.$errors.length && v$.elaboradorPgr.lazy.$dirty" class="p-error">
                    {{ v$.elaboradorPgr.$errors[0].$message }}
                </small>
            </div>
            <div class="field-checkbox mb-5">
                <InputSwitch id="enviarPorEmail" v-model="form.enviarPorEmail" />
                <label for="enviarPorEmail">Enviar notificação para o elaborador</label>
            </div>

            <div class="field p-fluid">
                <label for="emails">E-mail</label>
                <Chips
                    id="emails"
                    :disabled="!form?.enviarPorEmail"
                    v-model="v$.emails.$model"
                    placeholder="Digite um e-mail"
                    separator=","
                    :class="{ 'p-invalid': v$.emails.$errors.length && v$.emails.lazy.$dirty }"
                    aria-describedby="emails-help"
                />
                <small v-if="!v$.emails.$errors.length" id="emails-help"
                    >Digite um email e pressione Enter para adicionar; repita para cada novo email.</small
                >
                <small v-if="v$.emails.$errors.length && v$.emails.lazy.$dirty" class="p-error">
                    {{ v$.emails.$errors[0].$message }}
                </small>
            </div>
        </div>
    </Panel>
</template>
