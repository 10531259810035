const StatusGroGlobalEnum = Object.freeze({
    SEM_DADOS: 'SEM DADOS',
    A_LIBERAR: 'À LIBERAR',
    NOVA_UNIDADE: 'NOVA UNIDADE',
    EM_VISITA_TECNICA: 'EM VISITA TÉCNICA',
    AGUARDANDO_LPP: 'AGUARDANDO LPP',
    CONCLUIDO: 'CONCLUÍDO',
    EM_ELABORACAO: 'EM ELABORAÇÃO',
    VALIDADO: 'VALIDADO',
    EM_ANDAMENTO: 'EM ANDAMENTO'
});

export default StatusGroGlobalEnum;
