<script setup>
import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { defineExpose, ref, watch, defineProps, onMounted, reactive } from 'vue';
import { useToast } from 'primevue/usetoast';
import SesmtService from '../../../../../services/SesmtService';

import { showError } from '../../../../../utils/Toast';
import InfinityDropdownProfissionalSesmt from '../../../../profissionais-sesmt/components/InfinityDropdownProfissionalSesmt';

const props = defineProps({
    customerBranch: Object
});

const service = new SesmtService('liberacao-hierarquia-checklist');

const toast = useToast();
const form = ref({});

const customerBranchAtual = ref({});
const filtrosExtras = ref({ where: [] });
const hierarquiaIds = ref([]);

const rules = reactive({
    avaliador: {
        required: helpers.withMessage('O avaliador é obrigatório.', required),
        validacaoPersonalizadaAvaliador: helpers.withMessage(
            'O avaliador selecionado não possui usuário vinculado.',
            (value) => !!value?.usuarioVinculadoId
        ),
        lazy: true
    },
    emails: {
        required: helpers.withMessage(
            'Ao menos um e-mail deve ser informado',
            requiredIf(() => form.value?.enviarPorEmail)
        ),
        format: helpers.withMessage('Pelo menos um dos e-mails informados não é válido.', (value) => {
            if (!form.value?.enviarPorEmail || !value || !value.length) {
                return true;
            }
            const emailsAlterados = value.map((p) => p.trim());
            return emailsAlterados.every((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
        }),
        lazy: true
    }
});

const v$ = useVuelidate(rules, form);

watch(
    () => form.value.emails,
    async () => {
        validarCampoEmail();
    }
);

function updateEmails() {
    if (!form.value.enviarPorEmail) form.value.emails = [];
    const { enviarPorEmail, avaliador } = form.value;
    if (enviarPorEmail && avaliador?.usuarioVinculado?.email) {
        form.value.emails = [avaliador.usuarioVinculado.email];
    }
}

watch(() => form.value.avaliador, updateEmails);

watch(() => form.value.enviarPorEmail, updateEmails);

function montarFiltroCliente(customerId) {
    if (customerId) {
        filtrosExtras.value.where.push({
            prop: 'cliente.id',
            operator: 'equal',
            values: [customerId]
        });
    }
}

function montarFiltroUnidade(customerBranchId) {
    if (customerBranchId) {
        filtrosExtras.value.where.push({
            prop: 'customerBranch.id',
            operator: 'equal',
            values: [customerBranchId]
        });
    }
}

async function carregarHierarquias() {
    try {
        const { data } = await service.findAll({ filtrosExtras: filtrosExtras.value });
        hierarquiaIds.value = data.map((p) => p.hierarquiaId);
    } catch (error) {
        showError(toast, error);
    }
}

const validarCampoAvaliador = () => v$.value.avaliador.$touch();

const validarCampoEmail = () => v$.value.emails.$touch();

onMounted(async () => {
    customerBranchAtual.value = props.customerBranch;
    montarFiltroCliente(props.customerBranch?.cliente.id);
    montarFiltroUnidade(props.customerBranch?.id);
    await carregarHierarquias();
});

function validarCampos() {
    v$.value.$touch();
    if (!v$.value.$invalid) {
        return true;
    }
    return false;
}

function limparCampos() {
    form.value = {};
    v$.value.$reset();
    filtrosExtras.value = { where: [] };
}

defineExpose({ form, v$, validarCampos, limparCampos, hierarquiaIds });
</script>
<template>
    <Panel toggleable>
        <template #header>
            <div class="flex flex-column gap-2">
                <span class="font-bold">LPP</span>
                <span class="font-normal">Levantamento preliminar de perigo</span>
            </div>
        </template>

        <Message class="mb-5" severity="info" :closable="false">Os checkists serão liberados no aplicativo.</Message>
        <div class="field mb-5">
            <label for="avaliador">Avaliador técnico</label>
            <InfinityDropdownProfissionalSesmt
                id="avaliador"
                class="w-full"
                v-model="v$.avaliador.$model"
                :filtrosExtras="null"
                :class="{ 'p-invalid': v$.avaliador.$errors.length && v$.avaliador.lazy.$dirty }"
                @change="validarCampoAvaliador"
                showClear
            />
            <small v-if="v$.avaliador.$errors.length && v$.avaliador.lazy.$dirty" class="p-error">
                {{ v$.avaliador.$errors[0].$message }}
            </small>
        </div>
        <div class="field p-fluid">
            <label for="participantes">Participantes</label>
            <InputText class="w-full" id="participantes" v-model="form.participantes" />
        </div>
        <div class="field-checkbox mb-5">
            <InputSwitch id="enviarPorEmail" v-model="form.enviarPorEmail" />
            <label for="enviarPorEmail">Enviar notificação para o avaliador</label>
        </div>
        <div class="field p-fluid">
            <label for="emails">E-mail</label>
            <Chips
                id="emails"
                :disabled="!form?.enviarPorEmail"
                v-model="v$.emails.$model"
                placeholder="Digite um e-mail"
                separator=","
                :class="{ 'p-invalid': v$.emails.$errors.length && v$.emails.lazy.$dirty }"
                aria-describedby="emails-help"
            />
            <small v-if="!v$.emails.$errors.length" id="emails-help"
                >Digite um email e pressione Enter para adicionar; repita para cada novo email.</small
            >
            <small v-if="v$.emails.$errors.length && v$.emails.lazy.$dirty" class="p-error">
                {{ v$.emails.$errors[0].$message }}
            </small>
        </div>
    </Panel>
</template>
