<script setup>
import { ref, defineExpose, defineEmits, computed } from 'vue';

import { useAlert } from '../../../../../composables/useAlert';
import SesmtService from '../../../../../services/SesmtService';
import PanelLiberarPgr from './PanelLiberarPgr.vue';
import PanelLiberarLpp from './PanelLiberarLpp.vue';
import TipoProdutoServicoEnum from '../../../../../enums/TipoProdutoServicoEnum';
const { alertErrorData, alertSucess } = useAlert();

const service = new SesmtService('liberacao-produtos-unidade');
const emit = defineEmits(['onSuccessLiberacao', 'onErrorLiberacao']);
const isOpen = ref(false);
const isLoading = ref(false);

const submitted = ref(false);
const customerBranchAtual = ref({});
const loading = ref(false);
const panelLiberarPgr = ref();
const panelLiberarLpp = ref();
const produtoServicoOptions = ref([]);

function openDialog(data) {
    customerBranchAtual.value = data;
    getProdutoServicoOptions();
    isOpen.value = true;
}

const getProdutoServicoOptions = () =>
    (produtoServicoOptions.value = Object.keys(TipoProdutoServicoEnum).map(function (type) {
        return {
            label: `${TipoProdutoServicoEnum[type]}`,
            value: `${type}`
        };
    }));

const podeLiberarElaboracaoPgr = computed(() => {
    const produtoPgr = produtoServicoOptions.value.find((p) => p.label === TipoProdutoServicoEnum.PGR)?.value;
    const unidadeContemProdutoPgr = customerBranchAtual.value.produtoServicoUnidade?.some(
        (p) => p.produtoServico.tipoInventario === produtoPgr && p.produtoServico.ativo
    );

    return unidadeContemProdutoPgr;
});

const possuiProdutoLppContratado = computed(() => {
    const produtoLpp = produtoServicoOptions.value.find((p) => p.label === TipoProdutoServicoEnum.LPP)?.value;
    const contemLpp = customerBranchAtual.value.produtoServicoUnidade.some((p) => p.produtoServico.tipoInventario === produtoLpp);
    return contemLpp;
});

function closeDialog() {
    isOpen.value = false;
    panelLiberarPgr.value?.limparCampos();
    panelLiberarLpp.value?.limparCampos();

    submitted.value = false;
    loading.value = false;
}

async function liberar() {
    try {
        loading.value = true;
        if (
            (possuiProdutoLppContratado.value && !panelLiberarLpp.value?.validarCampos()) ||
            (podeLiberarElaboracaoPgr.value && !panelLiberarPgr.value?.validarCampos())
        )
            return;

        const emailsEnvioLiberacaoLpp = panelLiberarLpp.value?.form.enviarPorEmail ? panelLiberarLpp.value?.form.emails.map((p) => p?.trim()) : [];
        const participantesLpp = panelLiberarLpp.value?.form.participantes;
        const profissionalSesmtEnviarEmail = panelLiberarPgr.value?.form.enviarPorEmail
            ? panelLiberarPgr.value?.form.emails?.map((p) => p?.trim())
            : [];

        await service.save({
            clienteId: customerBranchAtual.value?.customerId,
            unidadeId: customerBranchAtual.value?.id,
            hierarquiaIds: panelLiberarLpp.value?.hierarquiaIds,
            responsavelLevantamentoIds: [panelLiberarLpp.value?.form.avaliador.id],
            emailsEnvioLiberacaoLpp,
            novaLiberacao: true,
            participantes: participantesLpp,
            customerBranchesId: customerBranchAtual.value?.id,
            profissionalSesmtId: panelLiberarPgr.value?.form.elaboradorPgr.id,
            profissionalSesmtEnviarEmail
        });
        alertSucess('Liberação realizada com sucesso.');
        closeDialog();
        emit('onSuccessLiberacao');
    } catch (error) {
        if (error?.response?.data?.details?.response?.retornoErrosCadastro?.contemErrosCadastro) {
            emit('onErrorLiberacao', error?.response?.data?.details?.response?.retornoErrosCadastro?.listaDeErros);
            closeDialog();
            return;
        }

        submitted.value = false;
        loading.value = false;
        alertErrorData(error, 'Não foi possível liberar a visita técnica, por favor tente novamente.');
    } finally {
        loading.value = false;
    }
}

defineExpose({
    openDialog
});
</script>
<template>
    <template>
        <Dialog
            v-model:visible="isOpen"
            :style="{ width: '430px' }"
            :header="`Liberar unidade ${customerBranchAtual?.name}`"
            @hide="closeDialog"
            :modal="true"
            :closable="!isLoading"
        >
            <div v-if="!isLoading" class="flex flex-column gap-4">
                <div class="flex flex-column gap-3">
                    <PanelLiberarLpp v-if="possuiProdutoLppContratado" :customerBranch="customerBranchAtual" ref="panelLiberarLpp" />
                    <PanelLiberarPgr v-if="podeLiberarElaboracaoPgr" :customerBranch="customerBranchAtual" ref="panelLiberarPgr" />
                </div>

                <div class="grupo-botoes-form">
                    <Button text label="Cancelar" class="p-button-outlined mr-4" :disabled="loading" @click="closeDialog" />
                    <Button class="button-salvar" label="Liberar" :loading="loading" @click="liberar" />
                </div>
            </div>
        </Dialog>
    </template>
</template>
<style scoped lang="scss">
.grupo-botoes-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    .button-link {
        margin-right: 20px;
    }
}
</style>
