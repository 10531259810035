<script setup>
import StatusGrid from '../StatusGrid.vue';
import { defineProps, onBeforeMount, ref } from 'vue';
import StatusGroGlobalEnum from '../../../../enums/StatusGroGlobalEnum';

const statusCustomerOptions = ref([]);

const styleMapStatus = {
    [StatusGroGlobalEnum.SEM_DADOS]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroGlobalEnum.NOVA_UNIDADE]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroGlobalEnum.EM_VISITA_TECNICA]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroGlobalEnum.AGUARDANDO_LPP]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroGlobalEnum.A_LIBERAR]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroGlobalEnum.EM_ELABORACAO]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroGlobalEnum.VALIDADO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroGlobalEnum.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

function getStatusComTodasAsConfigs(status) {
    return statusCustomerOptions.value.find((p) => p.value === status || p.label === status) ?? { label: 'NÃO INFORMADO OU INVÁLIDO' };
}

function getStatusCustomerOptions() {
    statusCustomerOptions.value = Object.keys(StatusGroGlobalEnum).map(function (type) {
        return {
            label: `${StatusGroGlobalEnum[type]}`,
            value: `${type}`,
            style: styleMapStatus[StatusGroGlobalEnum[type]]
        };
    });
}
const props = defineProps({
    status: {
        type: String
    },
    progresso: {
        type: Number
    },
    valorConcluido: {
        type: Number,
        default: 0
    },
    valorTotal: {
        type: Number,
        default: 0
    },
    mostrarBarraDeProgresso: {
        type: Boolean,
        default: true
    }
});
onBeforeMount(getStatusCustomerOptions);
</script>
<template>
    <div>
        <ProgressBar v-if="props.mostrarBarraDeProgresso" :showValue="false" style="height: 6px" :value="props.progresso" />
        <div class="mr-3 mt-2 flex flex-row" v-if="props.status && statusCustomerOptions.length">
            <div class="mr-3">{{ props.valorConcluido }}/{{ props.valorTotal }}</div>
            <StatusGrid :status="getStatusComTodasAsConfigs(props.status)" />
        </div>
    </div>
</template>
<style scoped lang="scss">
.p-progressbar .p-progressbar-value {
    background-color: #5e8f32 !important;
}
</style>
