const StatusGroCustomerBranchEnum = Object.freeze({
    NOVA_UNIDADE: 'NOVA UNIDADE',
    EM_VISITA_TECNICA: 'EM VISITA TÉCNICA',

    EM_ELABORACAO: 'EM ELABORAÇÃO',
    VALIDADO: 'VALIDADO',
    CONCLUIDO: 'CONCLUÍDO'
});

export default StatusGroCustomerBranchEnum;
